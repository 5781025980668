export const ApiConstant = {
  BASE_URL: process.env.REACT_APP_API_ENDPOINT,
  EMPLOYER_LISTING: "/employer/getAllEmployers",

  //profile
  GET_BY_ID_PROFILE: "/employee/getMyProfile",
  UPDATE_PROFILE: "/employee/updateMyProfile",
  REVOKE_CONSENT: "/nudge/nudgeEmployeeConsentRevoke",

  //employerAdmin profile
  GET_EMP_BY_ID_PROFILE: "/employer/employerByAdmin",
  UPDATE_EMP_PROFILE: "/employer/update/EmployerByAdmin",
  GET_BY_ID_MY_PROFILE: "employee/employeeAdminGetById",
  UPDATE_MY_PROFILE: "employee/employeeByAdminUpdate",
  GET_PENSION_FUNDS_LIST: "masterEntity/pensionFunds/getPensionFundsByEmployee",
  GET_ALL_ACTIVE_PENSION_FUNDS:
    "/masterEntity/pensionFunds/getAllActivePensionFunds",

  //products
  GET_ALL_PRODUCTS: "/product/getEmployeeProducts",
  ORG_ROLES: "/employee/getOrgRoles",

  //Employee
  EMPLOYEE_LISTING: "/employee/getAllEmployeesByAdmin",
  BULK_IMPORT_EMPLOYEE: "/employee/employeeImportByAdmin",
  INVESTMENT_ADVICE: "/employee/getRoboLink",
  DOWNLOAD_SAMPLE_FILE: "/employee/downloadSampleEmployeeImportFile",
  //NUDGE_CONSENT: "/product/productEmployeeConsent",
  //Dashboard
  DASHBOARD_EMPLOYEE_LISTING: "/employer/orgAdminDashboard",
  DASHBOARD_PRODUCTWISE_EMPLOYEE_LISTING: "/product/employeesByProduct",
  // BULK_IMPORT_EMPLOYEE: "/employee/employeeImportByAdmin",
  // INVESTMENT_ADVICE: "/employee/getRoboLink",
  DOWNLOAD_DASHBOARD_SAMPLE_FILE: "/product/exportEmployeesByProduct",
  SHOW_PRODUCT_INTEREST: "/product/tracking",
  // NUDGE_CONSENT: "/product/productEmployeeConsent"
  NUDGE_CONSENT: "/nudge/nudgeEmployeeConsent",
  DASHBOARD_PRODUCT_USAGE: "/employer/orgAdminDashboardProductUsageStat",
  DASHBOARD_PRODUCT_INTEREST: "/employer/orgAdminDashboardProductInterest",

  //Webinar
  GET_ALL_WEBINAR: "/webinarims/eventGetAll",
  GET_WEBINAR_CHECKAVLIABLITY: "/webinarims/getAttendeeById",
  BOOK_WEBINAR: "webinarims/attendeeCreateMMM",
  CANCEL_WEBINAR: "/webinarims/attendeeUpdateMMM",
  VIEW_MY_BOOKINGS: "/webinarims/bookedEventGetAll",
  GET_WEBINAR_BY_THEME: "/financialHealthCheck/getWebinarListingByThemeId",

  //financialHealthCheckup
  GET_FINANCIAL_HEALTH_CHECKUP: "/financialHealthCheck/getAllActiveThemes",
  SUBMIT_QUESTIONARY: "/financialHealthCheck/submitThemeResponse",
  GET_THEME_RESPONSE: "/financialHealthCheck/getThemeResponseScore",
  GET_THEME_EMP_DASHBOARD: "/financialHealthCheck/themeGetEmployeeDashboard",
  GET_ACTIVE_THEMES_WITH_WEBINARS: "/financialHealthCheck/getActiveThemesWithWebinars",
  DOWNLOAD_PRIVACY_DOCUMENT: "/employer/getDocument"
};
