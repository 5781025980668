import { initPlasmicLoader } from "@plasmicapp/loader-react";
import { getPlasmicTagName } from "utils/utils";
export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: "rLpAc4CSyddT3dW4oh5KaR", // ID of a project you are using
      version: getPlasmicTagName(),
      token:
        "a8rHaltC8385RmMXOTx2FLNqfaO2KaJ8hQqbJ3BynqaZVem3PcL5LStpell2M2ZluuCdDfIyyzVg4ctg", // API token for that project
    },
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: false
});
