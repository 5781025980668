import { useState } from "react";
import {
  OutlinedInput,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import { useAsyncDebounce, Row } from "react-table";
import { matchSorter } from "match-sorter";
import { SearchOutlined } from "@ant-design/icons";
interface GlobalFilterProps extends OutlinedInputProps {
  preGlobalFilteredRows: Row<{}>[];
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  ...other
}: GlobalFilterProps) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <OutlinedInput
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Search ${count} records...`}
      id="start-adornment-email"
      startAdornment={<SearchOutlined />}
      {...other}
    />
  );
}

export function DefaultColumnFilter({
  column: { filterValue, Header, setFilter },
}: any) {
  return (
    <TextField
      fullWidth
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={Header}
      size="small"
    />
  );
}

interface SearchFilterProps extends OutlinedInputProps {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

export function SearchFilter({
  globalFilter,
  setGlobalFilter,
  ...other
}: SearchFilterProps) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <OutlinedInput
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Search records...`}
      id="start-adornment-email"
      startAdornment={<i className="mmm-search"></i>}
      {...other}
    />
  );
}

function fuzzyTextFilterFn(rows: Row[], id: number, filterValue: string) {
  return matchSorter(rows, filterValue, {
    keys: [(row: Row) => row.values[id]],
  });
}

fuzzyTextFilterFn.autoRemove = (val: number) => !val;

export const renderFilterTypes: any = () => ({
  fuzzyText: fuzzyTextFilterFn,
  text: (rows: Row[], id: string, filterValue: string) => {
    rows.filter((row: Row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
});

export function roundedMedian(leafValues: any) {
  let min = leafValues[0] || 0;
  let max = leafValues[0] || 0;

  leafValues.forEach((value: number) => {
    min = Math.min(min, value);
    max = Math.max(max, value);
  });

  return Math.round((min + max) / 2);
}
